











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question529',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        language: 'fr',
      },
      options: [
        {
          text: 'A is the majority and B is the minority',
          value: 'AB',
        },
        {
          text: 'A is the majority and C is the minority',
          value: 'AC',
        },
        {
          text: 'B is the majority and A is the minority',
          value: 'BA',
        },
        {
          text: 'B is the majority and C is the minority',
          value: 'BC',
        },
        {
          text: 'C is the majority and A is the minority',
          value: 'CA',
        },
        {
          text: 'C is the majority and B is the minority',
          value: 'CB',
        },
      ],
      optionsFr: [
        {
          text: 'A est majoritaire et B est minoritaire',
          value: 'AB',
        },
        {
          text: 'A est majoritaire et C est minoritaire',
          value: 'AC',
        },
        {
          text: 'B est majoritaire et A est minoritaire',
          value: 'BA',
        },
        {
          text: 'B est majoritaire et C est minoritaire',
          value: 'BC',
        },
        {
          text: 'C est majoritaire et A est minoritaire',
          value: 'CA',
        },
        {
          text: 'C est majoritaire et B est minoritaire',
          value: 'CB',
        },
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q529.png';
    },
  },
};
